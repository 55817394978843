
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCIsIlRoYXQncyBpbnRlcmVzdGluZyI6IlRoYXQncyBpbnRlcmVzdGluZyIsIkdvb2QgdG8ga25vdyI6Ikdvb2QgdG8ga25vdyIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6IlRoYW5rIHlvdSBmb3Igc2hhcmluZyIsIlRoYXQncyB2ZXJ5IGhlbHBmdWwiOiJUaGF0J3MgdmVyeSBoZWxwZnVsIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJMZXQncyBnZXQgc3RhcnRlZCJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiW8em4bq94bq94bq94bmvIMOf4bq94bq94bq94bmv4bmv4bq94bq94bq9xZkgxLXDtsO2w7bDnyBtzILEg8SDxIPhua%2FDp%2BG4qeG6veG6veG6vcWhXSIsIlJlZmluZWQgZm9yIHlvdSI6IlvFmOG6veG6vcaSw6zDrOC4geC4teC5ieG6veG6vcaMIMaSw7bDtsWZIMO9w73DtsO2x5rHml0iLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IlvhuIrhur3hur3GksOsw6zguIHguLXguYnhur3hur0gw73DvcO2w7bHmseaxZkgxqXFmeG6veG6vcaS4bq94bq9xZnhur3hur3guIHguLXguYnDp%2BG6veG6vcWhXSIsIkJldGEiOiJb4bqe4bq94bq94bmvxIPEg10iLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJbxYHhur3hur3hur3hua8gbcyC4bq94bq94bq9IMWh4bq94bq94bq94bq94bq94bq9IMOsw6zDrMaSIMOPJ%2BG5veG6veG6veG6vSDEo8O2w7bDtuG5ryDhua%2FhuKnDrMOsw6zFoSDFmcOsw6zDrMSj4bip4bmvXSIsIlRoYXQncyBpbnRlcmVzdGluZyI6Ilvhua7huKnEg8SDxIPhua8nxaEgw6zDrMOs4LiB4Li14LmJ4bmv4bq94bq94bq9xZnhur3hur3hur3FoeG5r8Osw6zDrOC4geC4teC5icSjXSIsIkdvb2QgdG8ga25vdyI6IlvHpsO2w7bDtsO2w7bDtsaMIOG5r8O2w7bDtiDEt%2BC4geC4teC5icO2w7bDtsW1XSIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6Ilvhua7huKnEg8SDxIPguIHguLXguYnEtyDDvcO9w73DtsO2w7bHmseax5ogxpLDtsO2w7bFmSDFoeG4qcSDxIPEg8WZw6zDrMOs4LiB4Li14LmJxKNdIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6Ilvhua7huKnEg8SDxIPhua8nxaEg4bm94bq94bq94bq9xZnDvcO9w70g4bip4bq94bq94bq9xprGpcaSx5rHmseaxppdIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJbxYHhur3hur3hur3hua8nxaEgxKPhur3hur3hur3hua8gxaHhua%2FEg8SDxIPFmeG5r%2BG6veG6veG6vcaMXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiW8em4bq94bq94bq94bmvIMOf4bq94bq94bq94bmv4bmv4bq94bq94bq9xZkgxLXDtsO2w7bDnyBtzILEg8SDxIPhua%2FDp%2BG4qeG6veG6veG6vcWhXSIsIlJlZmluZWQgZm9yIHlvdSI6IlvFmOG6veG6vcaSw6zDrOC4geC4teC5ieG6veG6vcaMIMaSw7bDtsWZIMO9w73DtsO2x5rHml0iLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IlvhuIrhur3hur3GksOsw6zguIHguLXguYnhur3hur0gw73DvcO2w7bHmseaxZkgxqXFmeG6veG6vcaS4bq94bq9xZnhur3hur3guIHguLXguYnDp%2BG6veG6vcWhXSIsIkJldGEiOiJb4bqe4bq94bq94bmvxIPEg10iLCJMZXQgbWUgc2VlIGlmIEkndmUgZ290IHRoaXMgcmlnaHQiOiJbxYHhur3hur3hur3hua8gbcyC4bq94bq94bq9IMWh4bq94bq94bq94bq94bq94bq9IMOsw6zDrMaSIMOPJ%2BG5veG6veG6veG6vSDEo8O2w7bDtuG5ryDhua%2FhuKnDrMOsw6zFoSDFmcOsw6zDrMSj4bip4bmvXSIsIlRoYXQncyBpbnRlcmVzdGluZyI6Ilvhua7huKnEg8SDxIPhua8nxaEgw6zDrMOs4LiB4Li14LmJ4bmv4bq94bq94bq9xZnhur3hur3hur3FoeG5r8Osw6zDrOC4geC4teC5icSjXSIsIkdvb2QgdG8ga25vdyI6IlvHpsO2w7bDtsO2w7bDtsaMIOG5r8O2w7bDtiDEt%2BC4geC4teC5icO2w7bDtsW1XSIsIlRoYW5rIHlvdSBmb3Igc2hhcmluZyI6Ilvhua7huKnEg8SDxIPguIHguLXguYnEtyDDvcO9w73DtsO2w7bHmseax5ogxpLDtsO2w7bFmSDFoeG4qcSDxIPEg8WZw6zDrMOs4LiB4Li14LmJxKNdIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6Ilvhua7huKnEg8SDxIPhua8nxaEg4bm94bq94bq94bq9xZnDvcO9w70g4bip4bq94bq94bq9xprGpcaSx5rHmseaxppdIiwiTGV0J3MgZ2V0IHN0YXJ0ZWQiOiJbxYHhur3hur3hur3hua8nxaEgxKPhur3hur3hur3hua8gxaHhua%2FEg8SDxIPFmeG5r%2BG6veG6veG6vcaMXSJ9!"
        )
      )
      });
  
      export { translations as default };
    