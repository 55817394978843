import "src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.18_babel-plugin-macros@3.1.0_webpack@5.98.0/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+2SwXKCMBCG7z5FjnKIg6AoeOmbdGJYIRUSJkShdnz3Dqk4ZgVp771B/m9h99ss3pfKb+vskpKvGSGHAlqaCg3cCCUTcmZ6TmnPrL3d7Dpb9K9gSyqWpkJm1KgK85G3ewD2yhhVYmbjMAUcDCa2DqFFlj8hsdvYwTaWwxDK7NdKIelwvrd5I1KT44jbaM/4MdPqJFMqSpYBppaYqsUFEsLVGTRKNFTATEKkuj2ivFK1GNpD4I6b2XEf6rgqlMZFvluUTy0vdHlh+SYXBmhdMQ5d241mlUN9WOrhxnSDF6dS7pxjlpDQ96vWPeUJCaIVPt3cG4vZudAn33PzaCLfTuQxytHcRyy3X6c0TMiRhdHPhHCQptv4dfZWQioYqbkGkITJlMxL1tLbHVt3Ijz7F6RxVOSYyjGZr3SGHiaGhU4rfSX1h7iOyBCyl7FZ/cu4y4jj4K8ypj+6DH5737RqhvzG4aDfcBVN+40m/T4T2O8zgf1Gvd9vL8m8c90GAAA=\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.18_babel-plugin-macros@3.1.0_webpack@5.98.0/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var background = '_1o0xsgzg';
export var backgroundColour = 'var(--_1o0xsgz0)';
export var backgroundImage = 'var(--_1o0xsgz1)';
export var backgroundPosition = 'var(--_1o0xsgz2)';
export var centerImage = '_1o0xsgzk';
export var containerDirection = 'var(--_1o0xsgz5)';
export var containerLayout = '_1o0xsgzd';
export var contentGutter = '_1o0xsgze';
export var contentPaddingBottom = 'var(--_1o0xsgz7)';
export var contentPaddingLeft = 'var(--_1o0xsgz8)';
export var contentPaddingRight = 'var(--_1o0xsgz9)';
export var contentPaddingTop = 'var(--_1o0xsgz6)';
export var image = '_1o0xsgzf';
export var imageHeightVar = 'var(--_1o0xsgza)';
export var imageMinHeightVar = 'var(--_1o0xsgzb)';
export var imageWidthVar = 'var(--_1o0xsgzc)';
export var nowrap = '_1o0xsgzi';
export var responsive = '_1o0xsgzj';
export var title = '_1o0xsgzh';
export var titleFontSize = 'var(--_1o0xsgz4)';
export var titleTopPadding = 'var(--_1o0xsgz3)';