import { useTranslations } from '@vocab/react';
import { Button, IconAI, Stack, Text, TextLink } from 'braid-design-system';
import { useCallback, useMemo } from 'react';

import { useSignedInDashboardContext } from '../../SignedInDashboardContextProvider';
import { CareerFeedAIAssistantContentFrame } from '../CareerFeedAIAssistantContent/CareerFeedAIAssistantContentFrame';
import { useCareerFeedAIAssistant } from '../hooks/useCareerFeedAIAssistant';
import type { ErrorType } from '../types';

import translations from './.vocab';

const PrivacyAndCollectionNotice = () => {
  const { t } = useTranslations(translations);

  return (
    <Text size="xsmall" tone="secondary">
      {t('Don’t share sensitive information....', {
        CollectionNoticeLink: (children) => (
          <TextLink href="/collection-notice" weight="weak" target="_blank">
            {children}
          </TextLink>
        ),
        PrivacyLink: (children) => (
          <TextLink href="/privacy" weight="weak" target="_blank">
            {children}
          </TextLink>
        ),
      })}
    </Text>
  );
};

const CareerFeedAiAssistantInitialStateView = ({
  isButtonLoading,
  errorType,
  onButtonClick,
}: {
  isButtonLoading: boolean;
  errorType?: ErrorType | null;
  onButtonClick: () => void;
}) => {
  const { t } = useTranslations(translations);

  return (
    <CareerFeedAIAssistantContentFrame
      headMessage={{
        text: t(
          "Fine-tune your job recommendations – tell us what you're looking for with seekAi",
        ),
        size: 'small',
      }}
      errorType={errorType}
      ctaSection={
        <Stack space="small">
          <Button
            tone="brandAccent"
            icon={<IconAI />}
            loading={isButtonLoading}
            onClick={onButtonClick}
          >
            {t('Refine recommendations')}
          </Button>
          <PrivacyAndCollectionNotice />
        </Stack>
      }
      loading={isButtonLoading}
    />
  );
};

export const CareerFeedAiAssistantInitialState = () => {
  const {
    invokeCreateSession,
    isCreateSessionLoading,
    createSessionError,
    tracking: { trackGenAIChatStartPressed },
  } = useCareerFeedAIAssistant();
  const {
    recommendationsResult: { rawRecommendedJobs },
  } = useSignedInDashboardContext();

  const recommendedJobIds = useMemo(
    () => rawRecommendedJobs?.globalJobs.map((job) => job.id) ?? [],
    [rawRecommendedJobs],
  );

  const startSession = useCallback(() => {
    if (!isCreateSessionLoading) {
      trackGenAIChatStartPressed();
      invokeCreateSession(recommendedJobIds);
    }
  }, [
    invokeCreateSession,
    isCreateSessionLoading,
    recommendedJobIds,
    trackGenAIChatStartPressed,
  ]);

  return (
    <CareerFeedAiAssistantInitialStateView
      isButtonLoading={isCreateSessionLoading}
      errorType={createSessionError}
      onButtonClick={startSession}
    />
  );
};
