import { useTranslations } from '@vocab/react';
import { Button, Stack, Text } from 'braid-design-system';
import { useCallback, useEffect } from 'react';

import { CareerFeedAiAssistantInitialState } from '../CareerFeedAiAssistantInitialState/CareerFeedAiAssistantInitialState';
import { CareerFeedAssistantQuestions } from '../CareerFeedAssistantQuestions/CareerFeedAssistantQuestions';
import {
  useGetResetCandidateChatContext,
  useGetRefreshRecommendationsWithCandidateChatContext,
} from '../hooks/candidateChatContextHelpers';
import { useCareerFeedAIAssistant } from '../hooks/useCareerFeedAIAssistant';

import translations from './.vocab';
import { CareerFeedAIAssistantContentFrame } from './CareerFeedAIAssistantContentFrame';

const ConversationFinished = () => {
  const resetPreferences = useGetResetCandidateChatContext();
  const {
    lastCompletedAssistantResponse,
    tracking: {
      trackRecommendedJobsRefreshed,
      trackGenAIChatEnded,
      trackGenAIChatResetPressed,
    },
  } = useCareerFeedAIAssistant();
  const { t } = useTranslations(translations);

  const refreshRecommendations =
    useGetRefreshRecommendationsWithCandidateChatContext();

  const refreshRecs = useCallback(async () => {
    const result = await refreshRecommendations();
    if (result.success) {
      trackGenAIChatEnded();
      trackRecommendedJobsRefreshed({ jobsCount: result.jobsCount });
    } else {
      trackGenAIChatEnded(Boolean(result.error));
      trackRecommendedJobsRefreshed({ jobsCount: -1 });
    }
  }, [
    refreshRecommendations,
    trackGenAIChatEnded,
    trackRecommendedJobsRefreshed,
  ]);

  useEffect(() => {
    refreshRecs();
    // Ensuring that this will get called once. Having refreshRecs
    // as does trigger refresh multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReset = useCallback(() => {
    trackGenAIChatResetPressed('chat widget');
    resetPreferences();
  }, [resetPreferences, trackGenAIChatResetPressed]);

  const message = lastCompletedAssistantResponse?.text ?? '';

  return (
    <CareerFeedAIAssistantContentFrame
      headMessage={message}
      ctaSection={
        <Stack space="small">
          <Button onClick={onReset}>{t('Reset')}</Button>

          <Text size="xsmall" tone="secondary">
            {t('These AI-powered job suggestions....')}
          </Text>
        </Stack>
      }
      loading={
        false /* Loading is just used for loading overlay, we don't want this on the final q */
      }
    />
  );
};

const RecommendationRefinedConfirmation = () => {
  const resetPreferences = useGetResetCandidateChatContext();
  const {
    tracking: { trackGenAIChatResetPressed },
  } = useCareerFeedAIAssistant();
  const { t } = useTranslations(translations);

  const onReset = useCallback(() => {
    trackGenAIChatResetPressed('chat widget');
    resetPreferences();
  }, [resetPreferences, trackGenAIChatResetPressed]);

  return (
    <CareerFeedAIAssistantContentFrame
      headMessage={t(
        "We've updated your recommendations based on your answers. Take a look at the personalised job listings.",
      )}
      ctaSection={<Button onClick={onReset}>{t('Reset')}</Button>}
      loading={
        false /* Loading is just used for loading overlay, we don't want this on the final q */
      }
    />
  );
};

export const CareerFeedAIAssistantContent = () => {
  const {
    sequence,
    progress,
    tracking: {
      trackGenAIChatOutputDisplayed,
      trackGenAIChatStarted,
      trackGenAIChatCtaDisplayed,
    },
  } = useCareerFeedAIAssistant();

  useEffect(() => {
    if (progress === 'initial') {
      trackGenAIChatCtaDisplayed();
    } else if (progress !== 'recsRefined-withPersistedData') {
      trackGenAIChatOutputDisplayed();
    }

    if (sequence === 1) {
      trackGenAIChatStarted();
    }
  }, [
    progress,
    sequence,
    trackGenAIChatCtaDisplayed,
    trackGenAIChatOutputDisplayed,
    trackGenAIChatStarted,
  ]);

  switch (progress) {
    case 'initial':
      return <CareerFeedAiAssistantInitialState />;
    case 'recsRefined':
      return <ConversationFinished />;
    case 'recsRefined-withPersistedData':
      return <RecommendationRefinedConfirmation />;
    case 'gatheringRefinement':
    case 'collectGeneralRequirementsAtLast':
      return <CareerFeedAssistantQuestions />;
  }
};
